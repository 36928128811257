// @flow strict
import React from 'react';
import Copyright from './Copyright';
import styles from './Footer.module.scss';
import { useSiteMetadata } from '../../hooks';

const Footer = () => {
  const { copyright } = useSiteMetadata();

  return (
    <div className="footer">
        <Copyright copyright={copyright} />
    </div>
  );
};

export default Footer;
