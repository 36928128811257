// @flow strict
import React from 'react';
import Helmet from 'react-helmet';
import { useSiteMetadata } from '../../hooks';

const getSchemaOrgJSONLD = ({
  url,
  siteTitle,
  siteURL,
  title,
  description,
  datePublished,
  dateUpdated,
  isBlogPost,
  socialImage
}) => {
        const JSONLD = {
          '@context': 'http://schema.org',
          '@type': isBlogPost ? 'BlogPosting' : 'WebPage',
          url: url,
          name: title,
          headline: title,
          ...description ? {description: description} : null,
          author: {
            '@type': 'Person',
            name: 'Ben Millam',
          },
          publisher: {
            '@type': 'Organization',
            url: siteURL,
            name: 'Ben Millam',
          },
          ...isBlogPost ? {datePublished: datePublished} : null,
          ...dateUpdated ? {dateModified: dateUpdated} : null,
          ...socialImage ? {image: socialImage} : null
        };

        return JSONLD;
};

const SEO = ({metadata}) => {
  
  const { title: site_name } = useSiteMetadata();
  const siteTitle = metadata.siteTitle;
  const siteURL = metadata.siteURL;
  const title = metadata.pageTitle;
  const description = metadata.pageMetaDescription;
  //const image = `${config.url}${postImage}` || config.image;
  const url = metadata.pageURL;
  const socialImage = metadata.socialImage;
  const datePublished = metadata.publishedDate;
  const dateUpdated = metadata.updatedDate;
  const isBlogPost = metadata.isBlogPost;

  const schemaOrgJSONLD = getSchemaOrgJSONLD({
    url,
    siteTitle,
    siteURL,
    title,
    description,
    datePublished,
    dateUpdated,
    isBlogPost,
    socialImage
  });
  
  return (
    <Helmet>
      {/* General tags */}
      <html lang="en" />
      <title>{title}</title>
      {description ? <meta name="description" content={description} /> : null}
      {/*<meta name="image" content={image} />*/}

      {/* Schema.org tags */}
      <script type="application/ld+json">
        {JSON.stringify(schemaOrgJSONLD)}
      </script>

      {/* OpenGraph tags */}
      <meta property="og:url" content={url} />
      {isBlogPost ? <meta property="og:type" content="article" /> : null}
      <meta property="og:title" content={title} />
      {description ? <meta property="og:description" content={description} /> : null}
      <meta property="og:site_name" content={site_name} />
      <meta property="og:image" content={socialImage} />

      {/* Twitter Card tags */}
      {/*<meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={config.twitter} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />*/}
    </Helmet>
  );
};

export default SEO;